import React, {useState} from 'react'
import {graphql, Link} from 'gatsby'
import {format} from 'date-fns'
import GraphQLErrorList from '../components/graphql/error'
import SEO from '../components/seo'
import Layout from '../components/layout'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture,
  toPlainText,
  buildLinkedDataObj,
  buildCanonical
} from '../lib/helpers'
import Img from 'gatsby-image'
import PortableText from '../components/common/portableText'
import BlogPostPreview from '../components/post/preview'
import CategoryList from '../components/category/list'

export const query = graphql`
  query BlogPostTemplateQuery($id: String!) {
    post: sanityPost(id: {eq: $id}) {
      id
      _createdAt
      publishedAt
      _updatedAt
      categories {
        _id
        title
        slug {
          current
        }
      }
      mainImage {
        alt
        asset {
          _id
          url
          fluid {
            ...GatsbySanityImageFluid
          }
        }
      }
      title
      slug {
        current
      }
      _rawExcerpt(resolveReferences: {maxDepth: 5})
      _rawBody(resolveReferences: {maxDepth: 5})
      authors {
        _key
        author {
          _rawBio(resolveReferences: {maxDepth: 5})
          image {
            alt
            asset {
              fluid(maxWidth: 96, maxHeight: 96) {
                ...GatsbySanityImageFluid
              }
            }
          }
          name
          slug {
            current
          }
        }
      }
    }
    posts: allSanityPost(
      limit: 4
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            alt
            asset {
              _id
              url
              fluid(maxWidth: 360, maxHeight: 202) {
                ...GatsbySanityImageFluid
              }
            }
          }
          title
          _rawExcerpt
          slug {
            current
          }
          authors {
            _key
            author {
              image {
                alt
                asset {
                  fluid(maxWidth: 48, maxHeight: 48) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              name
              slug {
                current
              }
            }
          }
          _rawCategories(resolveReferences: {maxDepth: 1000})
        }
      }
    }
  }
`

const BlogPostTemplate = props => {
  const {data, errors} = props
  const post = data && data.post
  const {title, slug, _rawBody, _rawExcerpt, mainImage, publishedAt, _updatedAt} = post

  const postSEO = [
    {property: 'article:published_time', content: publishedAt},
    {property: 'article:modified_time', content: _updatedAt}
  ]

  const postNodes = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture)
    : []

  const [posts] = useState(postNodes)

  const ldJson = buildLinkedDataObj(post)
  const {author} = post.authors.length > 0 ? post.authors[0] : null

  return (
    <Layout>
      {errors && <SEO title='GraphQL Error' />}
      {post && <SEO canonical={buildCanonical(`/${slug.current}`)} title={title || 'Untitled'} description={toPlainText(_rawExcerpt)} image={mainImage} meta={postSEO} type={'article'} ldJson={ldJson} />}

      {errors && (
        <div className='container'>
          <GraphQLErrorList errors={errors} />
        </div>
      )}

      <article className='flex flex-col'>
        <div className='container flex flex-wrap md:mt-8'>
          <div className='w-full md:w-2/3 p-2 pl-0 md:p-12 md:pl-0 flex flex-col justify-start font-open-sans font-regular text-gray-700'>
            <div className='pt-4 md:pt-0'>
              {
                post.categories.length > 0 &&
                <CategoryList categories={post.categories} />
              }
            </div>
            <div className='flex flex-col'>
              <h1 className='font-raleway font-bold text-3xl sm:text-5xl text-gray-900 my-4 leading-none'>{title}</h1>
            </div>
            <div className='mt-3 flex items-center mb-6'>
              <div className='leading-5'>
                <Link className='flex hover:underline' to={`/author/${author.slug.current}`}>
                  <span className='text-md text-gray-700 opacity-80 hover:opacity-100 hover:text-gray-800'>By {author.name}</span>
                </Link>
              </div>
              <div className='flex ml-1 text-md leading-5 text-gray-700 opacity-80'>
                <span className='mx-1 text-2xl'>&middot;</span>
                <time dateTime={format(post.publishedAt, 'yyyy-MM-dd')}>{format(post.publishedAt, 'MMMM D, YYYY')}</time>
              </div>
            </div>

            {mainImage && mainImage.asset && (
              <div className='relative w-full'>
                <Img className='object-cover object-center shadow-md rounded-lg overflow mb-6 w-full' fluid={mainImage.asset.fluid} alt={mainImage.alt} />
              </div>
            )}
            {_rawBody && <PortableText blocks={_rawBody} />}
          </div>

          <aside className='w-full md:w-1/3 p-2 pr-0 md:p-12 md:pr-0 flex flex-col items-center'>
            {posts.map(post => <div key={post.id} className='mb-4 w-full'><BlogPostPreview key={post.id} post={post} /></div>)}
          </aside>
        </div>
      </article>
    </Layout>
  )
}

export default BlogPostTemplate
